import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const TeachersAndHeadteachersCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "11.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				sylviaA: file(
					relativePath: { eq: "testimonials/Sylvia Maths Teacher.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				daniel: file(relativePath: { eq: "testimonials/danielle-lloyd.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				francesT: file(
					relativePath: { eq: "testimonials/Frances Thapen.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title =
		"Teachers & Headteachers | Business & Executive Coaching in London";
	const description =
		"Whether you’re looking to build your own business or scale your current teaching business, moving from the public sector can have challenges you aren’t prepared for.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const francesT = data.francesT.childImageSharp.gatsbyImageData;
	const sylviaA = data.sylviaA.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Teachers & Headteachers",
				item: {
					url: `${siteUrl}/industries/teachers-and-headteachers`,
					id: `${siteUrl}/industries/teachers-and-headteachers`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/teachers-and-headteachers`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Teachers & Headteachers business & executive coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business and Executive Coach For Teachers"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								Whether you’re looking to build your own business or scale your
								current teaching business, moving from the public sector can
								have challenges you aren’t prepared for. This is where an
								experienced and professional business coach is so important.
							</p>
							<p>
								As a business coach, I offer objective feedback throughout our
								sessions, but also assist you in setting and achieving
								meaningful goals, as well as navigating the difficult decisions
								that come with starting or scaling a business in education.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Are business coaches the answer for teachers and school leaders?
							</h3>
							<p>
								With over 35 years of experience as a business coach, I've
								helped many teachers and senior leaders starting their own
								business or scaling an existing one, to navigate the
								complexities of educational leadership within their own
								businesses
							</p>
							<p>
								Whether you are aspiring for more in your professional and
								personal lives, face challenging decisions, or simply want a
								transformative change within their business, a business coach is
								the right partner for you.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								Take a look into your potential future
							</h3>

							<p>
								Imagine this: If you could peer into a crystal ball right now
								and see your time ahead, how different would your world be if
								you...
							</p>
							<ul className="pl-4">
								<li>
									Found yourself overflowing with self-assurance and self
									belief?
								</li>
								<li>
									Had honed your leadership and communication skills to
									perfection?
								</li>
								<li>
									Stood your ground with confidence whilst making decisions with
									ease?
								</li>
								<li>
									Felt a surge of motivation, a renewed passion for your
									business?
								</li>
								<li>
									Embraced a career shift or stepped up to a new role within
									your business with open arms?
								</li>
								<li>
									Or if you took that bold step, backing a new educational
									initiative?
								</li>
							</ul>
							<p>
								It's likely that life would look incredibly different if these
								'what ifs' become your reality.
							</p>
							<p>
								So, let me pose the question: What's stopping you from
								transforming these 'what ifs' into life changing 'whens'?
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What is Stopping You?</h2>
							<ul className="pl-4">
								<li>
									Feeling isolated at your school or tutoring business,
									overloaded by the weight of challenging decisions and
									responsibilities?
								</li>
								<li>
									Navigating the unique changes that come with leaving the
									public sector as a teacher, to creating or growing your own
									business?
								</li>
								<li>
									Understanding your core strengths and areas for growth, and
									how to leverage them effectively?
								</li>
								<li>
									Noticing a dip in your business productivity levels, unlike
									your usual self?
								</li>
								<li>
									Searching for that spark and purpose in life whether it be
									starting your own business or scaling your current one?
								</li>
								<li>
									Or perhaps you're caught in a standstill, seeking a path to
									progression?
								</li>
							</ul>
							<p>
								With all careers, especially in education, we encounter hurdles
								at one point or another. Yet, it's the way in which we tackle
								these challenges that will define our paths within our own
								educational business. When you find yourself at this point,
								making the right move is so important.
							</p>
							<p>
								This is where my business coaching for those starting or
								currently working within their own educational business steps
								in. Aimed at teachers and headteachers, my coaching sessions
								provide you with the guidance, strategies, and support needed to
								navigate the complexities of educational leadership, empowering
								you to make impactful decisions for yourself and your
								institution.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How business coaching can work for you
							</h2>
							<p>
								As an executive teaching coach, my focus is on empowering
								educational leaders like you—to navigate your unique challenges
								and opportunities, regardless if you are an executive
								headteacher at a private school, starting your own online
								business in education or already on your own journey to
								educational business success in need of assistance.
							</p>
							<p>
								Our online coaching sessions are scheduled at times that suit
								you and encompass a range of areas vital to both your
								professional and personal growth and well being:
							</p>
							<ul className="pl-4">
								<li>
									Professional and Personal Development: Enhance your skills and
									capacities to benefit your private school or business and
									personal aspirations.
								</li>
								<li>
									Leadership Coaching: Develop impactful leadership qualities
									that inspire and drive positive change within your own
									business.
								</li>
								<li>
									Business Coaching: Navigate the complexities of school
									management, from budgeting to strategic planning, ensuring
									success and sustainability.
								</li>
								<li>
									Career Coaching: Plot your career trajectory within the
									education sector, identifying opportunities for advancement
									and personal satisfaction.
								</li>
							</ul>
							<p>
								Differing from other business coaches who team with educational
								businesses that may rely on a one-size-fits-all approach, I
								offer bespoke sessions tailored specifically to meet the needs
								of you and your business. These personalised coaching
								experiences are designed to provide you with the direction and
								strategies necessary to flourish as a teacher or headteacher,
								fostering a positive future for both yourself and your business.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Why trusting a business and executive coach can bring you
								greater results
							</h2>
							<p>
								Having dedicated my career to self-employment, I've navigated
								the full spectrum of experiences—the successes and the
								inevitable challenges.
							</p>
							<p>
								Now, merging my business qualifications (DMS & MBA) with five
								years of specialised training in counselling and psychotherapy
								(Person-Centred and Transactional Analysis) and nearly four
								decades of business success, I am uniquely positioned to support
								educators and school leaders.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Some nice words from my coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						showLinkedIn="d-none"
						name="Danielle Lloyd – TEA Teaching"
						review="Jason is very professional and has vast experience working with a variety of people and uses different approaches depending on his mentee. I have found him a joy to work with as he really understands me and my business. He goes that extra mile to support and help and I warmly recommend him to anyone looking to grow their business."
						profilePic={daniel}
					/>
					<HorizontalTestimonial
						showLinkedIn="d-none"
						name="Sylvia de Araujo. The School of Learning and Achievement"
						review="Without hesitation, I would recommend Jason as a business coach. I contacted Jason when my business was, and still is in its very early stages. In only a short time, Jason has helped me to see the potential of my business, with his expert advice and wealth of knowledge of upscaling any business. However, not only does Jason, with yourself, look at planning to grow your business, he also understands the importance of having the correct mindset to achieve the goals you want to achieve. Jason’s input is invaluable to myself; as I continue on my journey to entrepreneurship."
						profilePic={sylviaA}
					/>
					<HorizontalTestimonial
						linkedIn="https://www.linkedin.com/in/francesthapen/"
						name="Frances Thapen. School Development Ltd"
						review="I've worked with Jason for nearly two years now and I love how fearless I feel in his sessions. He helps me to question all of my assumptions about working in education and helps me to build the business and the lifestyle that I want. My company is set up to support and provide bespoke curriculum services to schools in the UK. When I started working with Jason, I was a self -employed consultant working on my own and I now run a small agency with up to 10 consultants working remotely, partnering specialist consultants with classroom teachers. I'm really looking forward to our next session because since I last saw Jason a couple of weeks ago, I've decided to set up an app to serve my schools even better and by the time I meet him next week, I'll have got the first draft almost completed. It's looking really good and it's going to open a whole extra service channel for me to be able to bring the work that we do to more schools. One of the parts I loved most about working with Jason was that in our first session, he identified a part of my character that I hadn't really noticed before, that I was much more suited to working as a consultant within an agency. That insight helped me to develop a business which matched my personality better, that helped me to find a way of working that could bring out my strengths rather than fit into a mould."
						profilePic={francesT}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to achieve professional growth?
							</h2>
							<p>
								While we may not have a crystal ball to predict what lies ahead
								for your business, I can't specify exactly how your educational
								business might transform if you embrace new teaching methods,
								awaken each day brimming with confidence and joy, or
								significantly enhance the performance of your school or tutoring
								business. However, one thing is crystal clear:
							</p>
							<p>If we continue to resist change, nothing will evolve.</p>
							<p>
								It's remarkable how much we can accomplish together when we
								utilise the power of coaching to concentrate and reflect on your
								true aspirations within the education sector.
							</p>
							<p>
								With everything to gain and nothing to lose, why not schedule an
								introductory call with me? Let's explore the possibilities that
								await you and your career.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Book a call today</h3>
							<p>
								Let's chat, get to know each other and see if there's a way for
								us to work together with some business coaching for teachers and
								headteachers.
							</p>
							<p>
								P.S. Remember, no matter how good you are in your role, you know
								full well that you can't do it alone. You need a trusted
								executive coaching partner who can provide objective feedback,
								help you set and accomplish goals, and help you make tough
								decisions.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default TeachersAndHeadteachersCoachingPage;
